export default class ItemExtension {
  constructor(item) {
    Object.assign(this, item)
  }
  imageUrl() {
    if (this.Images != null && this.Images[0]) {
      return process.env.VUE_APP_URL + this.Images[0].ImageUrl
    } else {
      return false
    }
  }
}
