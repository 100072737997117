var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"page-container"},[(_vm.parentCategoryID)?_c('Breadcrumbs',{attrs:{"currentpage":_vm.parentCategoryID}}):_vm._e(),(_vm.loggedIn && !_vm.loggedOut)?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v("Je bent nu ingelogd. ")],1):_vm._e(),(_vm.loggedOut)?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v("Je bent nu uitgelogd. ")],1):_vm._e(),(_vm.loggedOutOtherLocation)?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v("Je bent uitgelogd omdat je ingelogd bent op een andere locatie of omdat je sessie verlopen is. ")],1):_vm._e(),_c('b-row',_vm._l((_vm.categories),function(category){return _c('b-col',{key:category.CategoryID,staticClass:"mb-4",attrs:{"xs":"12","md":"6","lg":"4","xl":"3"}},[(!category.ChildCategories || _vm.parentCategoryID)?_c('b-card',{staticClass:"category",attrs:{"hide-footer":true,"header":category.Description.toUpperCase()},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('router-link',{attrs:{"to":{
              path: ("/catalogus/" + _vm.parentCategoryID + "/" + (category.Slug))
            }}},[_vm._v(_vm._s(category.Description.toUpperCase()))])]},proxy:true},{key:"footer",fn:function(){return [_c('router-link',{attrs:{"to":{
              path: ("/catalogus/" + _vm.parentCategoryID + "/" + (category.Slug))
            }}},[_vm._v("Bekijk alle "+_vm._s(category.Description)+" artikelen"),_c('span',{staticClass:"arrow-link"},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)])]},proxy:true}],null,true)},[_c('b-card-text',[_c('div',{staticClass:"mask"}),(_vm.parentCategoryID)?_c('ChildItems',{attrs:{"parent-category-slug":_vm.parentCategoryID,"category-slug":category.Slug,"category-i-d":category.CategoryID}}):_vm._e()],1)],1):_vm._e(),(category.ChildCategories)?_c('b-card',{staticClass:"category",attrs:{"header":category.Description.toUpperCase(),"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(category.CategoryID && category.CategoryID === 'Pakketten')?_c('router-link',{attrs:{"to":{
              path: "/pakketten"
            }}},[_vm._v(_vm._s(category.Description.toUpperCase()))]):_c('router-link',{attrs:{"to":{
              path: ("/catalogus/" + (category.Slug))
            }}},[_vm._v(_vm._s(category.Description.toUpperCase()))])]},proxy:true},(
            category.ChildCategories != null &&
              category.Description != 'Pakketten'
          )?{key:"footer",fn:function(){return [_c('router-link',{attrs:{"to":{
              name: 'catalogus',
              params: {
                categoryDescription: category.Description,
                categoryID: category.Slug
              }
            }}},[_vm._v("Bekijk alle "+_vm._s(category.Description.toLowerCase())+" categorieën"),_c('span',{staticClass:"arrow-link"},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)])]},proxy:true}:{key:"footer",fn:function(){return [(category.Description != 'Pakketten')?_c('router-link',{attrs:{"to":{
              name: 'items',
              params: {
                subcategoryID: category.Slug,
                categoryID: category.ParentCategoryID
              }
            }}},[_vm._v("Bekijk alle "+_vm._s(category.Description.toLowerCase())),_c('span',{staticClass:"arrow-link"},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)]):_vm._e(),(category.Description == 'Pakketten')?_c('router-link',{attrs:{"to":{
              name: 'packages'
            }}},[_vm._v("Bekijk alle "+_vm._s(category.Description.toLowerCase())),_c('span',{staticClass:"arrow-link"},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)]):_vm._e()]},proxy:true}],null,true)},[_c('b-card-text',[_c('div',{staticClass:"mask"}),(
              category.ChildCategories != null &&
                category.Description != 'Pakketten'
            )?_c('ChildCategories',{attrs:{"categories":category.ChildCategories,"parent-category-slug":category.Slug}}):_c('ChildItems',{attrs:{"packages":true,"category-i-d":category.CategoryID,"package-items":_vm.packages}})],1)],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }