<template>
  <header class="mb-3">
    <b-container>
      <ul class="list-inline  medium-w-100">
        <li
          col
          xs="12"
          sm="12"
          md="6"
          lg="3"
          class="list-inline-item text-sm-center text-md-left"
        >
          <header-logo />
        </li>
        <li class="list-inline-item  medium-w-100">
          <p class="title">UITLEENDIENST</p>
        </li>

        <div class="inline-right">
          <li class="list-inline-item medium-w-100 v-align-top">
            <router-link
              v-if="user.UserName != null"
              class="cart-item"
              :to="{ name: 'cart' }"
            >
              <b-button variant="outline-primary" class="mr-3">
                <font-awesome-icon icon="shopping-cart" />
                {{ cartCount }}
                Item(s)
              </b-button>
            </router-link>
          </li>
          <li
            class="list-inline-item small-w-100 d-none d-lg-inline-block d-xl-inline-block"
          >
            <header-search />
          </li>

          <li
            class="list-inline-item text-right medium-w-100 links v-align-top"
          >
            <a href="https://www.vlaamsbrabant.be/uitleendienst" class="btn"
              >Contact</a
            >
            <router-link
              v-if="user.UserName == null"
              :to="{ name: 'login' }"
              class="btn"
              >Inloggen</router-link
            >
            <router-link
              v-if="user.UserName != null"
              class="btn"
              variant="clear"
              :to="{ name: 'account' }"
              >Je account</router-link
            >
            <b-button
              v-if="user.UserName != null"
              class=" btn"
              variant="clear"
              @click="logout"
              >Uitloggen</b-button
            >
          </li>
          <li class="list-inline-item small-w-100 d-block d-lg-none d-xl-none">
            <header-search />
          </li>
        </div>
      </ul>
    </b-container>
  </header>
</template>

<script>
import HeaderLogo from '@/components/header/HeaderLogo'
import HeaderSearch from '@/components/header/HeaderSearch'
export default {
  components: {
    'header-logo': HeaderLogo,
    'header-search': HeaderSearch
  },

  computed: {
    user() {
      return this.$store.getters.user
    },
    cartCount() {
      if (this.adminCosts && this.$store.getters.cartCount > 0)
        return this.$store.getters.cartCount - 1
      return this.$store.getters.cartCount
    },
    cartCache: function() {
      return this.$store.getters.cartCache
    },
    adminCosts: function() {
      if (!this.cartCache) return false
      if (
        this.cartCache?.CartItems?.Collection.find(
          cartItem => cartItem.ItemID === 'ADMIN'
        )
      )
        return true

      return false
    }
  },

  methods: {
    logout: async function() {
      await this.$store.commit('unsetUser')
      await this.$store.commit('unsetCart')

      this.$router.push({ name: 'index-loggout', query: { loggedOut: true } })
    }
  }
}
</script>

<style scoped lang="scss">
.v-align-top {
  vertical-align: top;
}
.links {
  a {
    padding-left: 0px !important;
  }
}
.title {
  font-size: 26px;
  font-family: 'DecimaPro-bold';
  vertical-align: middle;
  margin: auto;
  padding-left: 40px;
}

header {
  background-color: white;
  min-height: 90px;
  ul {
    padding-top: 20px;
    position: relative;
  }
}
.cart-item button {
  font-family: 'DecimaPro-bold';
  font-size: 20px !important;
  height: 50px;
}
a,
button {
  text-decoration: none;
  font-size: 20px;
  font-family: 'DecimaPro';
}
.logo {
  height: 50px;
  margin-top: 10px;
}

.inline-left,
.inline-right {
  position: absolute;
  display: inline;
}

.inline-right {
  right: 0;
}

@media (max-width: 1343px) {
  .inline-left,
  .inline-right {
    position: relative;
    display: inline;
  }
  li {
    padding-bottom: 10px;
  }
  .lg-w-100 {
    width: 100%;
    text-align: center !important;
  }
}

@media (max-width: 767px) {
  .medium-w-100 {
    width: 100%;
    text-align: center !important;
  }
  .title {
    padding-left: 0px;
  }
}
@media (max-width: 600px) {
  .small-w-100 {
    width: 100%;
    text-align: center;
  }
}
</style>
