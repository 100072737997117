<template>
  <footer>
    <b-container class="mt-5 mb-4">
      <b-row>
        <b-col cols="12">
          <ul class="list-inline">
            <li class="list-inline-item footer-xl footer-sm">
              <img
                class="logo"
                alt="Logo Provincie Vlaams-Brabant"
                src="/img/logo.png"
              />
            </li>
            <li class="list-inline-item footer-xl footer-sm">
              &copy; {{ new Date().getFullYear() }} Provincie Vlaams-Brabant
            </li>
            <li class="list-inline-item footer-lg">
              <img
                class="logo"
                alt="Logo Provincie Vlaams-Brabant"
                src="/img/logo.png"
              />
              &copy; {{ new Date().getFullYear() }} Provincie Vlaams-Brabant
            </li>
            <li class="list-inline-item pl-2 seperator-item footer-xl">
              <div class="seperator"></div>
            </li>
            <li class="list-inline-item pl-2 pr-3">
              <a href="/" selected>Home</a>
            </li>

            <li class="list-inline-item">
              <a
                class="pr-3"
                href="https://www.vlaamsbrabant.be/faq-uitleendienst"
                selected
                >Veelgestelde vragen</a
              >
            </li>
            <li class="list-inline-item">
              <a
                class="pr-3"
                href="https://www.vlaamsbrabant.be/uitleendienst"
                selected
                >Contact</a
              >
            </li>
            <li class="list-inline-item">
              <a
                class="pr-3"
                href="https://www.vlaamsbrabant.be/uitleendienst"
                selected
                >Openingsuren</a
              >
            </li>
            <li class="list-inline-item">
              <a
                class="pr-3"
                href="https://www.vlaamsbrabant.be/proclaimer"
                selected
                >Disclaimer</a
              >
            </li>
            <li class="list-inline-item">
              <a
                class="pr-3 "
                href="https://www.vlaamsbrabant.be/uitleendienst"
                selected
                >Nieuwsbrief</a
              >
            </li>
            <li class="list-inline-item seperator-item footer-xl">
              <div class="seperator"></div>
            </li>
            <li class="list-inline-item pl-4">
              <span class="icon-link">
                <a
                  href="https://www.facebook.com/uitleenvlaamsbrabant/"
                  alt="Facebook"
                  aria-label="hidden"
                >
                  <span class="d-none">Facebook</span>
                  <font-awesome-icon :icon="['fab', 'facebook-square']" />
                </a>
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <cookie-law v-if="!googleCrawler" theme="vlaamsbrabant">
      <div slot-scope="props" class="w-100">
        <b-container class="pt-4 pb-3">
          <b-row>
            <b-col md="9">
              <p>
                <strong
                  >Provincie Vlaams-Brabant gebruikt cookies om je surfervaring
                  te verbeteren.</strong
                ><br />
                Door op "Ik ga akkoord" te drukken, aanvaard je het gebruik van
                deze cookies.

                <a href="https://vlaamsbrabant.be/nl/over-deze-website/cookies"
                  >Meer info</a
                >.
              </p>
            </b-col>
            <b-col class="text-center text-md-right">
              <b-button variant="outline-light" @click="props.accept">
                <span>Ik ga akkoord</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import { checkGoogleCrawler } from '@/functions/checkGoogleCrawler'
export default {
  components: { CookieLaw },
  computed: {
    googleCrawler() {
      return checkGoogleCrawler()
    }
  }
}
</script>
<style scoped lang="scss">
footer {
  .icon-link {
    a {
      color: black;
      font-size: 16px;
    }
  }
  background-color: white;
  font-family: 'Roboto';

  .footer-xl {
    display: inline-block;
  }

  .footer-lg,
  .footer-xs {
    display: none;
  }

  li {
    display: inline-flex;
    vertical-align: middle;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }

  .logo-title-in-one {
    display: none;
  }

  .seperator {
    height: 21px;
    width: 1px;
    background-color: #282828;
  }

  .logo {
    width: 86px;
    height: 36px;
  }
}
@media (max-width: 1200px) {
  footer {
    text-align: center;
    .footer-xl {
      display: none;
    }
    .footer-lg {
      display: block;
    }
  }
}

@media (max-width: 991px) {
  footer {
    .footer-xl {
      display: none;
    }
    .footer-lg {
      display: block;
    }
    .pl-4,
    .pr-3 {
      padding: 0 !important;
    }
    ul {
      li {
        width: 100% !important;
        text-align: center;
        margin: auto;
        display: block;
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 523px) {
  .footer-lg {
    display: none !important;
  }
  .footer-sm {
    display: inline-block !important;
  }
}
</style>
