<template>
  <b-form inline @submit.prevent="search">
    <div class="input-group w-100 mb-3">
      <b-form-input
        v-model="searchTerm"
        :state="searchValidation"
        class="mb-2 mr-sm-2 mb-sm-0 searchbar"
        placeholder="Zoeken in de catalogus..."
        min="2"
      ></b-form-input>
      <div class="input-group-append">
        <b-button variant="primary" type="submit">Zoeken</b-button>
      </div>
    </div>
    <div></div>
  </b-form>
</template>

<script>
export default {
  data: function() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    searchValidation: function() {
      if (
        this.searchTerm.length > 1 ||
        this.searchTerm.length == 0 ||
        this.searchTerm == ''
      ) {
        return null
      } else {
        return false
      }
    }
  },
  methods: {
    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm.toLowerCase() }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.searchbar {
  height: 50px !important;

  min-width: 200px !important;
  border-radius: 0.25rem !important;
}
.btn {
  border-radius: 0.25rem !important;
  height: 50px !important;
  margin-left: 10px;
}
.form-control {
  border: 2px solid #cbcbcb;
}
.form-control:focus {
  box-shadow: none !important;

  border: 2px solid #015ea5;
}
</style>
