const { axiosCreate } = require('./Api')
import store from '../state/store'
//import axios from 'axios'

async function getCategories() {
  // if (!store.getters.token) {
  //   return await axios.get(
  //     'https://vlaams-brabant-shop-service.rentmagic-shop.workers.dev/api/v1/labelfiltercategories'
  //   )
  // }
  return axiosCreate.get('labelfiltercategories', {
    params: { contactID: store.getters.contactID },
    headers: { authorization: store.getters.token }
  })
}

async function getCategory({ categoryID = null, slug = false } = {}) {
  let categoryIDList = []

  if (categoryID && categoryID.toString().includes(',')) {
    categoryIDList = categoryID.split(',')
  } else {
    categoryIDList.push(categoryID)
  }

  // if (!store.getters.token) {
  //   const result = await axios.get(
  //     `https://vlaams-brabant-shop-service.rentmagic-shop.workers.dev/api/v1/labelfiltercategories/${
  //       slug ? 'slug/' : ''
  //     }${categoryIDList.reverse()[0]}`
  //   )
  //   return result.data
  // }

  const result = await axiosCreate.get(
    `labelfiltercategories/${slug ? 'slug/' : ''}${
      categoryIDList.reverse()[0]
    }`,
    {
      params: { ContactID: store.getters.contactID },
      headers: { authorization: store.getters.token }
    }
  )
  return result.data
}

module.exports = { getCategories, getCategory }
