<template>
  <header class="">
    <b-container>
      <b-row>
        <b-col
          md="12"
          lg="4"
          xl="3"
          class="text-center text-lg-left text-xl-left"
        >
          <header-logo
        /></b-col>
        <b-col md="12" lg="8" xl="9">
          <b-row class="first-row ">
            <b-col
              ><ul
                class="list-inline text-center text-lg-right text-xl-right inline-right"
              >
                <li class="list-inline-item">
                  <router-link
                    v-if="user.UserName != null"
                    class="cart-item"
                    :to="{ name: 'cart' }"
                  >
                    <b-button variant="outline-primary" class="mr-3">
                      <font-awesome-icon icon="shopping-cart" />
                      {{ cartCount }}
                      Item(s)
                    </b-button>
                  </router-link>
                </li>

                <li
                  class="list-inline-item text-center text-lg-right text-xl-right small-w-100 links"
                >
                  <a
                    href="https://www.vlaamsbrabant.be/uitleendienst"
                    class="btn"
                    >Contact</a
                  >
                  <router-link
                    v-if="user.UserName == null"
                    :to="{ name: 'login' }"
                    class="btn"
                    >Inloggen</router-link
                  >
                  <router-link
                    v-if="user.UserName != null"
                    class="btn"
                    variant="clear"
                    :to="{ name: 'account' }"
                    >Je account</router-link
                  >
                  <b-button
                    v-if="user.UserName != null"
                    class=" btn"
                    variant="clear"
                    @click="logout"
                    >Uitloggen</b-button
                  >
                </li>
              </ul></b-col
            >
          </b-row>
          <b-row class="second-row pt-lg-5 pt-xl-5 ">
            <b-col>
              <h1
                v-if="isHomePage"
                class="title mb-3 text-center  text-xl-left pt-0 "
              >
                UITLEENDIENST
              </h1>
              <p
                v-else
                class="title mb-3 text-center text-lg-left text-xl-left pt-0 "
              >
                >UITLEENDIENST
              </p></b-col
            >
            <b-col xs="12" sm="12" md="12" lg="6"><header-search /></b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import HeaderLogo from '@/components/header/HeaderBannerLogo'
import HeaderSearch from '@/components/header/HeaderSearch'
export default {
  components: {
    'header-logo': HeaderLogo,
    'header-search': HeaderSearch
  },

  computed: {
    user() {
      return this.$store.getters.user
    },
    cartCount() {
      if (this.adminCosts && this.$store.getters.cartCount > 0)
        return this.$store.getters.cartCount - 1
      return this.$store.getters.cartCount
    },
    isHomePage() {
      return this.$route.path === '/'
    }
  },

  methods: {
    logout: async function() {
      await this.$store.commit('unsetUser')
      await this.$store.commit('unsetCart')

      this.$router.push({ name: 'index-loggout', query: { loggedOut: true } })
    },
    adminCosts: function() {
      if (!this.cartCache) return false
      if (
        this.cartCache.CartItems.Collection.find(
          cartItem => cartItem.ItemID === 'ADMIN'
        )
      )
        return true

      return false
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 1200px) {
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 1200px) {
  .pt-lg-5 {
    padding-top: 0px !important;
  }
  .row {
    ul.list-inline.text-center.text-lg-right.text-xl-right {
      text-align: center !important;
    }
    div.text-lg-left {
      text-align: center !important;
    }
    p.text-lg-left {
      text-align: center !important;
    }
  }
}
.first-row {
  height: 146px;
}
.title-mb {
  padding-top: 82px;
}
.links {
  a {
    padding-left: 0px !important;
    color: white;
  }

  .btn {
    color: white;
  }
  .btn-outline-primary {
    color: white !important;
  }
}
.title {
  height: 75px;
  font-size: 50px;
  font-family: 'DecimaPro-bold';
  vertical-align: middle;
  margin: auto;

  color: white;
  margin-top: -10px;
}

header {
  background-color: white;
  min-height: 300px;
  ul {
    padding-top: 20px;
    position: relative;
  }
  background: url('/img/header-banner.png') no-repeat center center;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.cart-item button {
  font-family: 'DecimaPro-bold';
  font-size: 20px !important;
  height: 50px;
}
a,
button {
  text-decoration: none;
  font-size: 20px;
  font-family: 'DecimaPro';
  color: white;
}

button {
  .btn-outline-primary {
    border-color: white;
  }
  .btn-outline-primary:hover {
    color: #000;
    background-color: white;
  }
}

.inline-left,
.inline-right {
  position: absolute;
  display: inline;
}

.inline-right {
  right: 0;
}
@media (max-width: 1343px) {
  .inline-left,
  .inline-right {
    position: relative;
    display: inline;
  }
  li {
    padding-bottom: 10px;
  }
  .lg-w-100 {
    width: 100%;
    text-align: center !important;
  }
}

@media (max-width: 767px) {
  .medium-w-100 {
    width: 100%;
    text-align: center !important;
  }
  .title {
    padding-left: 0px;
  }
}
@media (max-width: 600px) {
  .small-w-100 {
    width: 100%;
    text-align: center;
  }
}
</style>
