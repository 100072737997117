<template>
  <div class="breadcrumbs">
    <p>
      <span v-for="(breadCrumb, index) in breadCrumbs" :key="breadCrumb.index">
        <router-link
          v-if="breadCrumb.page"
          :to="{ name: breadCrumb.page, params: breadCrumb.params }"
          >{{ breadCrumb.title }}</router-link
        >
        <router-link v-else :to="{ path: breadCrumb.path }">{{
          breadCrumb.title
        }}</router-link>
        <span
          v-if="
            index < breadCrumbs.length - 1 ||
              (index < breadCrumbs.length && currentpage)
          "
          class="seperator"
        >
          &nbsp;<font-awesome-icon icon="chevron-right" />
        </span>
      </span>
      <span class="currentpage breadcrumb-link">{{
        capitalizeFirstLetter(currentpage)
      }}</span>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    breadCrumbs: {
      type: Array,
      required: false,
      default: function() {
        return [{ page: 'index', title: 'Uitleendienst' }]
      }
    },

    currentpage: {
      type: String,
      default: function() {
        return null
      }
    }
  },
  methods: {
    capitalizeFirstLetter: function(string) {
      if (!string) return ''
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>
<style lang="scss" scoped>
p {
  font-size: 18px;
  font-family: 'DecimaPro';
}
.seperator {
  font-size: 7px;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 6px;
  padding-right: 6px;
  color: #333333;
}
.currentpage {
  color: #070808;
}
</style>
